import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "src/types/api/Client";

export interface AuthResponse {
	accessToken: string;
	idToken: string;
	refreshToken: string;
	user: UserMe,
	expiresIn: number,
	anonymous: boolean,
	incompletedRegister: boolean,
	missingUserName: boolean
}

export interface UserMe {
	id: string,
	name: string,
	document: string,
	email: string,
	emailVerified: boolean,
	phoneNumber: string,
	phoneNumberVerified: boolean,
	loggedWith?: string,
	notifications: {
		pn_email: boolean,
		pn_sms: boolean
	},
	settings?: {
		fingerprint: boolean
	},
	address?: {
		cep?: string,
		uf?: string,
		city?: string,
		neighborhood?: string,
		address?: string,
		number?: string,
		complement?: string
	}
}

export interface AuthState {
	user: UserMe | null,
	accessToken: string | null,
	idToken: string | null,
	refreshToken: string | null,
	expiresIn: Date | null,
	anonymous: boolean | null
}

const initialState: AuthState = {
	user: null,
	accessToken: null,
	idToken: null,
	refreshToken: null,
	expiresIn: null,
	anonymous: null
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		login: (state, action: PayloadAction<AuthResponse>) => {
			state.accessToken = action.payload.accessToken;
			state.idToken = action.payload.idToken;
			state.user = action.payload.user;
			state.expiresIn = new Date(Date.now() + action.payload.expiresIn * 1000);
			state.anonymous = action.payload.anonymous;

			if (action.payload.refreshToken)
				state.refreshToken = action.payload.refreshToken;
		},
		updateProfile: (state, action: PayloadAction<Client>) => {
			state.user ??= {} as UserMe;

			state.user.name = action.payload.name;
			state.user.email = action.payload.email ?? "";
			state.user.emailVerified = action.payload.emailVerified ?? true;
			state.user.phoneNumber = action.payload.phoneNumber;
			state.user.phoneNumberVerified = action.payload.phoneNumberVerified ?? true;
			state.user.address = {
				cep: action.payload.address?.cep,
				uf: action.payload.address?.uf,
				city: action.payload.address?.city,
				neighborhood: action.payload.address?.neighborhood,
				address: action.payload.address?.address,
				number: action.payload.address?.number,
				complement: action.payload.address?.complement,
			};
		},
		logout: (state) => {
			state.user = null;
			state.idToken = null;
			state.accessToken = null;
			state.refreshToken = null;
			state.anonymous = null;
		},
	},
});

export const { login, logout, updateProfile } = authSlice.actions;
export default authSlice.reducer;
